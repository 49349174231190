import React from 'react'
import styles from '../css/subpages.module.css'
import { useMediaQuery } from 'react-responsive';
import $ from 'jquery'


const Comp = (props) =>{
	const {state, isDesktop, color, id, stop, interval} = props
  const [index, setIndex] = React.useState(1)
  const [isLoaded, setLoaded] = React.useState(false)
  const isLarge = useMediaQuery({ query: '(min-width: 1300px)' });
  const isXLarge = useMediaQuery({ query: '(min-width: 2000px)' });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {

    if(!isLoaded){
      $('#'+id).carousel({
        interval: interval || 5000,
        pause: 'hover',
        keyboard: true,
        wrap: true,
        touch: true,

      })
      if(Boolean($('#'+id).length)){
        // console.log('useEffect3', $('#'+id).length, Boolean($('#'+id).length))
        setLoaded(true)
      }
    }
    

    $('#'+id).on('slid.bs.carousel', function (e) {
      let CurInd = document.querySelector(`#${id} > div > div.carousel-item.active`).getAttribute('index');
      setIndex(CurInd);
    }); 

  })

  const navigateSlide = (e) => {
    const direction = e.target.getAttribute('data-slide')
    // console.log('navigateSlide', direction)
    $('#'+id).carousel(direction)
    let CurInd = document.querySelector(`#${id} > div > div.carousel-item.active`).getAttribute('index');
      setIndex(CurInd);
  }


	return(
	<div className={`${isDesktop ? '' : 'mt-5'} col d-flex flex-column ${styles.rightColumn}`}>
    <div className={`rightimg ${styles.rightimg}`}>
      {state.sliderF && (state.sliderF.gallery || state.sliderF.video_links) &&
      <div id={id ? id : "carouselIndicators"} className="carousel slide" data-ride="carousel" data-wrap={!stop} /*data-interval={!interval}*/ data-interval="1000">
        <div className="carousel-inner" style={{minHeight: isXLarge ? "350px" : isLarge ? "300px" : "197px"}}>
          {
            state.sliderF && state.sliderF.gallery && state.sliderF.gallery.map((x, i) => {
              return(
              <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`} index={i + 1}>
                { x.type === "image" ?
                 <img className="d-block w-100" src={`/images/${x.url.split('wp-content/')[1]}`} alt={x.alt} style={{maxWidth: "100%", maxHeight: "100%"}}/>
                  : 
                  <video className="video-fluid" autoPlay loop muted controls style={{maxWidth: "100%"}} index={i + 1}>
                    <source src={`/images/${x.url.split('wp-content/')[1]}`} type={x.mime_type} />
                  </video>
                }
              </div>
            )}
            )
          }
          {
            state.sliderF && state.sliderF.video_links && state.sliderF.video_links.map((x, i) => 
              x.video_link &&
              <div id="last" key={i} className={`carousel-item`} index={i + 1 + (state.sliderF.gallery ? state.sliderF.gallery.length : 0)}>
                <video className="video-fluid" autoPlay loop muted controls style={{maxWidth: "100%"}} index={i + 1}>
                  <source src={x.video_link} />
                </video>
              </div>
            )
          }
        </div>
        
        {state.sliderF && (state.sliderF.gallery || state.sliderF.video_links) &&
        <>
        <a className="carousel-control-prev" href="#carouselIndicators" onClick={navigateSlide} role="button" data-slide="prev">
          <span className={`carousel-control-prev-icon ${styles.carouselControl}`} aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselIndicators" onClick={navigateSlide} role="button" data-slide="next">
          <span className={`carousel-control-next-icon ${styles.carouselControl}`} aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
        </>
        }
        
      </div>
      }
      
      { state.sliderF &&
        <div style={{display: "flex"}}>
        {state.sliderF && state.sliderF.gallery &&
            [...Array(state.sliderF.gallery.length + (state.sliderF.video_links && state.sliderF.video_links.video_link ? state.sliderF.video_links.length : 0))].map((_, i) => (
              <div key={i} data-target={id ? `#${id}` : "#carouselIndicators"} data-slide-to={i} className={`${styles.slide1} ${color === "blue" ? styles.blueSlide : ''} ${color === "purple" ? styles.purpleSlide : ''} ${color === "green" ? styles.greenSlide : ''} ${color === "yellow" ? styles.yellowSlide : ''} ${color === "bordeau" ? styles.bordeauSlide : ''} ${parseInt(index) === (i + 1) ? styles.active : null}`} style={{marginRight:  i === (state.sliderF.gallery.length + (state.sliderF.video_links && state.sliderF.video_links.video_link ? state.sliderF.video_links.length : 0) - 1) ? "0px" : null}}></div>
          ))  
        }  
        </div>
      }
    </div>
  </div>
)}

export default Comp;